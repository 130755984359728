import { TeamIcon } from "./svgIcons";
import { NotificationOutlined, FileSearchOutlined, TruckOutlined, DeploymentUnitOutlined, TeamOutlined, VideoCameraOutlined} from '@ant-design/icons';

export const sidebarList = [
  {
    name: "Users  Management",
    icon: <TeamIcon />,
    link: "/members", //"/team",
  },
  {
    name: "Vehicle Management",
    icon: <TruckOutlined />,
    link: "/vehicle",
  },
  {
    name: "Request Management",
    icon: <DeploymentUnitOutlined />,
    link: "/request",

  },
  
  {
    name: "Equipment Management",
    icon: <VideoCameraOutlined />,
    link: "/equipment-management",
  },
  {
    name: "Admin Management",
    icon: <TeamOutlined />,
    link: "/admin-management",
  },
  {
    name: "Daily Log Generator",
    icon: <FileSearchOutlined />,
    link: "/daily-log-generator",
  },
  {
    name: "Notification Center",
    icon: <NotificationOutlined />,
    link: "/notification-center"
  }
];
