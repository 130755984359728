import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./utils/theme";
import { Provider } from "react-redux";
import store, { persistor } from "./services/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
  theme={{
    components: {
      Token: {        
        colorPrimary:"#f48d56",
        colorText:"#f48d56"
        /* here is your component tokens */
      },
    },
  }}
>
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
    
          <App />
      
      </ThemeProvider>
    </Provider>
  </PersistGate>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
