import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import { getAllProcoreProjectsApi } from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";
import { DatePicker, Select } from "antd";
import { IconButton } from "rsuite";
import { Search } from "@rsuite/icons";
import { generateInvoice } from "../../utils/InvoiceGenerator";
import {  message } from "antd";

const { Option } = Select;

const EquipmentWrapper = styled(OuterContainer)`
  .heading-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }

  .form-section {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .form-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .selectProject {
    width: 300px; /* Make this wider */
  }
`;

export const DailyLog = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const getAllProcoreProjects = async () => {
    try {
      const res = await getAllProcoreProjectsApi();
      if (res?.status === 200) {
        setProjects(res?.data?.data);
      } else {
        errorMessage(res);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
      getAllProcoreProjects();
  }, []);

  const handleProjectChange = (value) => {
    setSelectedProject(value);
    console.log("Selected project:", value);
  };

return (
    <EquipmentWrapper>
        <div className="heading-section">
            <p>Daily Log Generator</p>
        </div>
        <div className="form-section">
            <div className="form-item">
                <label>Select Project:</label>
                <Select
                    className="selectProject"
                    showSearch
                    placeholder="Select a project"
                    optionFilterProp="children"
                    onChange={handleProjectChange}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {projects.map((project) => (
                        <Option key={project.id} value={project.id}>
                            {project.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <br />
            <div className="form-item">
                <label>Start Date:</label>
                <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    maxDate={endDate} // Add maxDate prop
                />
            </div>
            <div className="form-item">
                <label>End Date:</label>
                <DatePicker
                    showIcon
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate} // Add minDate prop
                />
            </div>
            <IconButton
                icon={<Search />}
                onClick={() => {
                    if (startDate && endDate && startDate <= endDate && endDate < new Date() && selectedProject) {
                        generateInvoice(startDate, endDate, selectedProject);
                    } else {
                        // Handle validation error
                        message.error("Date range is invalid or project is not selected.")
                    }
                }}
                color="orange"
                appearance="primary"
                className="refress-btn"
                style={{ marginLeft: "100px" }} // Add left spacing
            />
        </div>
    </EquipmentWrapper>
);
};