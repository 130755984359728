
import axiosInterceptor from "./index";

export const postApi = async (url, data) => {
  try {
    const result = await axiosInterceptor.post(url, data);
    return result;
  } catch (e) {
    return e;
  }
};

export const deleteApi = async (url, data) => {
  try {
    const result = await axiosInterceptor.delete(url, { params: data });
    return result;
  } catch (e) {
    return e;
  }
};
 
export const getApi = async (url, data) => {
  try {
    const result = await axiosInterceptor.get(url, data);
    return result;
  } catch (e) {
    return e;
  }
};

export const putApi = async (url, data) => {
  try {
    const result = await axiosInterceptor.put(url, data);
    return result;
  } catch (e) {
    return e;
  }
};
