import { message } from "antd";

export const errorMessage = (result) =>
  message.error(result?.message || "Something went wrong");

export const activeRoute = () => {
  const currentRoute = window.location.pathname;
  return url_connected_SidebarName[currentRoute] ?? "User Management";
};

const url_connected_SidebarName = {
  "/users": "User Management",
  "/members": "Member Management", //"Team Management",
  "/team-members": "Team Management",
  "/vehicle": "Vehicle Management",
  "/project-management": "Project Management",
  "/new-requests": "Project Management",
  "/labour-equipment-management": "Labour & Equipment Management",
};
