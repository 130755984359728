import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import { defaultTheme } from "../../utils/theme";

const ButtonComponent = ({
  text = "button",
  loading = false,
  onClick,
  htmlType,
}) => {
  return (
    <CustomeButton
      type="primary"
      loading={loading}
      onClick={onClick}
      size="large"
      htmlType={htmlType || ""}
    >
      {text}
    </CustomeButton>
  );
};
export default ButtonComponent;

const CustomeButton = styled(Button)`
  background-color: ${defaultTheme.primaryColor};
  font-weight: 600;
  &:hover {
    background-color: ${defaultTheme.white}!important;
    border: 1px solid ${defaultTheme.primaryColor};
    color: ${defaultTheme.primaryColor}!important;
  }
`;
