import styled from "styled-components";
import { defaultTheme } from "../../utils/theme";

export const FormWrap = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  //   position: relative;
  flex-direction: column;
  background: linear-gradient(
    0deg,
    ${defaultTheme.primaryColor} 50%,
    ${defaultTheme.white} 50%
  );
  //   z-index: 4;
`;

export const LoginForm = styled.div`
  width: 364px;
  max-width: 100%;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
  background: #ffffff;
  border-radius: 16px;
  padding: 25px 40px;
`;

export const Logintext = styled.div`
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 20px 0px;
  font-family: ${({ theme }) => theme?.fontFamily};
`;

export const ForgetWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  a {
    color: ${({ theme }) => theme?.primaryColor};
  }
`;

export const ProjectName = styled.div`
  //   position: absolute;
  //   top: 40px;
  font-size: 30px;
  font-weight: 600;
  .project-name {
    color: ${({ theme }) => theme?.primaryColor};
  }
`;

export const BtnWrap = styled.div`
  margin: 10px 0;
  text-align: center;
  .submit-btn {
    background: ${({ theme }) => theme?.primaryColor};
    font-family: ${({ theme }) => theme?.fontFamily};
    border-radius: 8px;
    padding: 5px 15px;
    border: none;
    &:hover {
      background: ${({ theme }) => theme?.primaryColor}!important;
      color: ${({ theme }) => theme?.white}!important;
    }
  }
`;

export const SignUpLink = styled.div`
  text-align: center;
  a {
    color: ${({ theme }) => theme?.primaryColor};
  }
`;
