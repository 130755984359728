import React, { useState } from "react";
import styled from "styled-components";
import { UserIcon } from "../../utils/svgIcons";
import { useDispatch, useSelector } from "react-redux";
import PopOver from "../popover";
import { LogoutOutlined, DownOutlined } from "@ant-design/icons";
import ModalComponent from "../modal";
import ConfirmModal from "../modal/confirmModal";
import { authlogout } from "../../services/redux/signIn";
import Mobileheader from "./Mobileheader";

const logoutSubheading = "You really want to Logout the App?.";

export default function Header({width}) {
  const { loginUser } = useSelector((e) => e?.signIn?.data) || {};
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authlogout());
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  return (
    <HeaderWrapper>
      {width>1050? <div></div>:<Mobileheader/>}
     
      <PopOver
        content={
          <>
            <List onClick={handleOpenModal}>
              <LogoutOutlined />
              Logout
            </List>
          </>
        }
      >
        <UserInfo>
          <div className="userLogo">
            <UserIcon />
          </div>
          {loginUser?.firstName}
          <DownOutlined />
        </UserInfo>
      </PopOver>
      {openModal && (
        <ModalComponent openModal={openModal} setOpenModal={handleCloseModal}>
          <ConfirmModal
            subheading={logoutSubheading}
            handleCancel={handleCloseModal}
            handleConfirm={handleLogout}
            confirmBtnText="Sure"
            icon={<LogoutOutlined />}
          />
        </ModalComponent>
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  padding: 10px 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const List = styled.div`
  // padding: 5px;
  width: 70px;
  font-weight: 500;
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  .userLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: ${({ theme }) => theme?.primaryColor};
    svg {
      fill: white;
    }
  }
`;
