import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import Authentication from "./signIn";
import { persistReducer, persistStore } from "redux-persist";
import Refresstoken from "./Refresstoken";

import Loadingdata from './Loader'

const reducers = combineReducers({
  signIn: Authentication,
  Refresstoken:Refresstoken,
  Loadingdata:Loadingdata
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
});

export default store;
export const persistor = persistStore(store);
