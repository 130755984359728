import React, { memo } from "react";
import { Pagination } from "antd";
import styled from "styled-components";
import { defaultTheme } from "../../utils/theme";

const CustomePagination = ({
  current = 1,
  onPageChange,
  total = 1,
  defaultPageSize,
}) => {
  return (
    <PaginationWrapper className="pagination">
      <Pagination
        current={current}
        onChange={onPageChange}
        total={total}
        defaultPageSize={defaultPageSize || 10}
        showSizeChanger={false}
      />
    </PaginationWrapper>
  );
};
export default memo(CustomePagination);

const PaginationWrapper = styled.div`
  background: white;
  text-align: right;
  .ant-pagination-item-active,
  .ant-pagination-item-active:hover {
    border-color: ${defaultTheme.primaryColor};
    a {
      color: ${defaultTheme.primaryColor};
    }
  }
`;
