import { DateTime, Interval } from 'luxon';

export function extractNumberFromString(str) {
  const match = str.match(/^\d+/);
  return match ? parseInt(match[0], 10) : null;
}

export function calculateHours(timeIn, timeOut) {
  const timeInDateTime = DateTime.fromISO(timeIn);
  const timeOutDateTime = DateTime.fromISO(timeOut);
    console.log("Time in:", timeIn, "Time out:", timeOut);

  // Create intervals for the time in, time out, and regular hours
  const workInterval = Interval.fromDateTimes(timeInDateTime, timeOutDateTime);
  const regularInterval = Interval.fromDateTimes(
    timeInDateTime.set({ hour: 8, minute: 0 }),
    timeInDateTime.set({ hour: 17, minute: 0 })
  );

  // Calculate total working hours
  const totalWorkingHours = workInterval.toDuration('hours').hours;

  // Calculate overlap with regular hours
  const regularHoursInterval = workInterval.intersection(regularInterval);
  let regularHours = regularHoursInterval ? regularHoursInterval.toDuration('hours').hours : 0;

  // Calculate overtime hours
  let overtimeHours = totalWorkingHours - regularHours;
  overtimeHours = Math.max(overtimeHours, 0); // Ensure no negative overtime hours

  // Check for working more than 8 hours
  if (regularHours > 8) {
    overtimeHours += regularHours - 8;
    regularHours = 8;
  }

  // Round to the nearest tenth of an hour
  regularHours = Math.round(regularHours * 10) / 10;
  overtimeHours = Math.round(overtimeHours * 10) / 10;

  return { regularHours, overtimeHours };
}

export function formatTime(time) {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const cost_code_map = 
{
  "GENERAL LABOR": 45.0,
  "SKILLED LABOR": 55.0,
  "PROJECT ADMINISTRATIVE ASSISTANT": 60.0,
  "SKILLED TECHNICIAN (ST)": 65.0,
  "PROJECT SUPERVISOR": 70.0,
  "REMEDIATION SUPERVISOR": 100.0,
  "PROJECT MANAGER": 105.0,
  "PROJECT ESTIMATOR": 130.0,
  "SENIOR PROJECT MANAGER (SP)": 170.0,
  "SENIOR PROJECT COORDINATOR": 200.0,
  "MOLD TECHNICIAN": 65.0,
  "4-429 - BOX TRUCK": 100.0,
  "1-1-1 - AIR MOVER- AXIAL FAN": 32.0,
  "1-1-2 - AIR MOVER- CARPET FAN": 32.0,
  "4-435 - AIR MOVER- CARPET FAN": 32.0, // MIGHT GET DEPRECATED
  "1-2-1 - WATER EXTRACTOR - OLYMPUS HYDRO FORCE - PORTABLE EXTRACTOR": 125.0,
  "1-2-112 - ANABEC ADVANCED CLEANING SOLUTION + BLUE": 200.0,
  "4-464 - HAND TOOLS- MICELLANEOUS": 20.0,
  "1-1-5 - AIR SCRUBBER- SYCLONE HEPA AIR SCRUBBER": 125.0,
  "1-2-14 - MOISTURE READING EQUIPMENT": 20.0,
  "1-2-46 - THERMAL IMAGING CAMERA": 150.0,
  "1-2-54 - BAGS, TRASH 3 MIL BLACK": 0.8,
  "4-500 - BAGS, TRASH 3 MIL BLACK": 0.8, //DEPRECATE LATER
  "1-2-33 - HAND TOOLS- POWER (SAW, CIRCULAR)": 30.0,
  "4-465 - HAND TOOLS- POWER (SAW, CIRCULAR)": 30.0, // DEPRECATE LATER
  "4-531 - POLY - 6 MIL FIRE RETARDANT (2K SF)": 125.0,
  "4-468 - LADDER- 8 TO10'": 10.0,
  "4-460 - FLOOR KIT (BUCKET, BROOM, ETC NO INCLUDING MOP HEAD)": 20.0,
  "4-430 - EMERGENCY RESPONSE VEHICLE - EQUIPPED": 125.0,
  "4-458 - EXTENSION CORD- GENERAL USE": 3.5,
  "4-460 - FLOOR KIT (BUCKET, BROOM, ETC NO INCLUDING MOP HEAD": 20.0,
  "4-493 - TRASH CONTAINERS- 45 GAL.": 15.0,
  "4-530 - POLY - 3 MIL COVER (2K SF)": 55.0,
  "2-315 - CLEANING CHEMICALS/MATERIALS": 50.0, // MIGHT DEPRECATE LATER
  "4-438 - AIR SCRUBBER- PHOENIX GUARDIAN II": 125.0,
  "4-495 - VACUUM- HEPA": 75.0,
  "4-540 - TAPE - PAINTER'S TAPE": 9.0,
  "4-560 - BIOESQUE: BOTANICAL DISINFECTANT SOLUTION": 200.0,
  "4-442 - DEHUMIDIFIER-PORTABLE LGR PHOENIX 200/250 MAX": 140.0,
  "3-210 - SUPPLIES ANABEC, FORSTERS, IAQ": 200.0,
  "4-480 - PAINT SPRAYER (AIRLESS)": 100.0,
  "3-2 - ANABEC ADVANCED CLEANING SOLUTION BLUE": 75.0,
  "3-1 - ANABEC NEWBUILD 50": 250.0,
  "4-437 - AIR SCRUBBER- MINI (FILTER 415 CFM) PHOENIX": 125.0,
  "1-1-12 - DEHUMIDIFIER - LGR 6000LI": 180.0, // MIGHT GET DEPRECATED LATER
  "4-562 - DEHUMIDIFIER - LGR 6000LI": 180.0, // MIGHT GET DEPRECATED LATER
  "1-1-12 - DEHUMIDIFIER - 25 GPD": 180.0,
  "4-484 - PUMP UP SPRAYER": 15.0,
  "4-559 - BIOESQUE: MOLD RESISTANT COATING": 250.0,
  "4-515 - FILTER - HEPA FOR VACUUM": 250.0,
  "4-473 - LIGHT, STRING": 15.0,
  "3-210 - SUPPLIES ANABEC, IAQ": 75.0,
  "4-457 - EXTENSION CORD- 10 GAUGE SUPER HEAVY DUTY (50')": 20.0,
  "4-510 - DUST MASKS N95 (20 PER BOX)": 30.0,
  "4-522 - GLOVES MEDIUM DUTY": 9.0,
  "4-520 - GLOVES - BASIC WORK GLOVES": 6.0,
  "4-488 - SHOVEL/BROOM": 7.0,
  "OMNIDRY - LOW PROFILE AIR MOVER": 35.0,
  "DEHUMIDIFIER - LGR 6000LI": 180.0,
  "SYCLONE AIR SCRUBBER": 125.0,
  "4-545 - TYVEK SUITS": 18.0,
  "4-496 - VACUUM- SHOP VACUUM": 40.0,
  "4-486 - RESPIRATOR- HALF FACE W/ CARTRIDGE": 35.0,
  "4-534 - SHOE COVERS": 30.0,
  "4-537 - TAPE - DUCT (STD. STRENGTH)": 9.0,
  "4-486 - RESPIRATOR- FULL FACE W/ CARTRIDGE": 35.0,
  "INDUSTRIAL HYGIENIST": 85.0,
  "4-558 - DEHUMIDIFIER - LGR 6000LI": 180.0
}