import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/header";
import Sidebar from "../components/sidebar";

const Landing = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(()=>{
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
    
  },[])

  return (
    <LandingWrapper width={windowWidth}>
      {windowWidth>1050?<div className="landingSidebar">
        <Sidebar />
      </div>:''}
      
      <div className="landingRightSection">
        <Header width={windowWidth} />
        <Outlet />
      </div>
    </LandingWrapper>
  );
};

export default Landing;

const LandingWrapper = styled.div`
  display: flex;
  .landingSidebar {
    height: 100vh;
    min-height: 550px;
    width: 250px;
  }
  .landingRightSection {
     //width: calc(100% - 250px);
     width: ${({width})=>width>1050?"calc(100% - 250px)":"100%"};
    font-family: ${({ theme }) => theme?.fontFamily};
    height: 100vh;
  }
`;
