/* eslint-disable no-unused-vars */
import ExcelJS from 'exceljs';
import { getTimecardList, getQuantitiesList, getEquipmentList } from '../services/Api/collection';
import * as formattingConstants from './formattingConstant';
import { calculateHours, formatTime, cost_code_map, extractNumberFromString } from './invoiceUtils';

const {
    LABOR_HEADING_COLOR,
    LABOR_HEADING_PATTERN,
    LABOR_HEADING_TYPE,
    LABOR_HEADING_ALIGNMENT,
    LABOR_COLUMN_WIDTHS,
    LABOR_COLUMN_COLOR,
    LABOR_COLUMN_PATTERN,
    LABOR_COLUMN_TYPE,
    LABOR_COLUMN_ALIGNMENT,
    ALL_OUTLINE,
    ALL_OUTLINE_COLOR,
} = formattingConstants;

export async function generateInvoice(startDate, endDate, projectId, accessToken) {
    const filename = `project_${projectId}_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.xlsx`;
    console.log("Generated filename:", filename);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Daily Logs');

    const startDateString = startDate.toISOString().split('T')[0];
    const endDateString = endDate.toISOString().split('T')[0];

    try {
        const timecardsResponse = await getTimecardList(projectId, startDateString, endDateString, accessToken);
        const quantitiesResponse = await getQuantitiesList(projectId, startDateString, endDateString, accessToken);
        const equipmentResponse = await getEquipmentList(projectId, startDateString, endDateString, accessToken);

        if (!timecardsResponse || !quantitiesResponse || !equipmentResponse) {
            throw new Error("Failed to fetch data from the API");
        }

        const timecards = JSON.parse(timecardsResponse.data);
        const quantitiesData = JSON.parse(quantitiesResponse.data);
        const equipmentData = JSON.parse(equipmentResponse.data);

        if (!timecards || !quantitiesData || !equipmentData) {
            throw new Error("One of the API responses is empty or invalid");
        }

        generateLaborData(worksheet, timecards, startDate, endDate);
        generateQuantitiesData(worksheet, quantitiesData, startDate, endDate);
        generateEquipmentData(worksheet, equipmentData, startDate, endDate);

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        URL.revokeObjectURL(link.href);
    } catch (error) {
        console.error("Error generating invoice:", error);
    }
}

function generateLaborData(worksheet, timecards, start_date, end_date) {
    generateLaborHeading(worksheet);
    generateLaborColumns(worksheet);
    var currentDate = new Date(start_date);

    var totals = [];

    while (currentDate <= end_date) {
        const currentDateString = currentDate.toISOString().split('T')[0];
        const currDateTimecards = timecards.filter(entry => entry.date === currentDateString);

        if (currDateTimecards.length === 0) {
            currentDate.setDate(currentDate.getDate() + 1);
            continue;
        }

        let startingRow = worksheet.lastRow.number + 1;
        currDateTimecards.forEach((entry) => {
            console.log(entry);
            const { regularHours, overtimeHours } = calculateHours(entry.time_in, entry.time_out);
            let current_row = worksheet.lastRow.number + 1;
            let cost_code_name = entry.cost_code?.name;
            const rate = cost_code_map[cost_code_name.toUpperCase()];

            const addedRow = worksheet.addRow([
                current_row === startingRow ? currentDate.toISOString().split('T')[0] : "",
                entry.party.name,
                cost_code_name,
                formatTime(entry.time_in),
                formatTime(entry.time_out),
                regularHours,
                rate,
                overtimeHours,
                0,
                0,
                regularHours + overtimeHours,
                {
                    formula: `($F${current_row}*$G${current_row}) + ($H${current_row}*(1.5*$G${current_row}))`
                }
            ]);
        });
        const endingRow = worksheet.lastRow.number;
        const totalRows = worksheet.addRow(["", "", "", "", "", "", "", "", "", "", "Day Total:", { formula: `=SUM(L${startingRow}:L${endingRow})`}]);
        totals.push(worksheet.lastRow.number);

        generateWhiteSpace(worksheet);

        currentDate.setDate(currentDate.getDate() + 1);
    }
    var formula = "=";
    totals.forEach((row) => { formula += `L${row} + `; });
    const subtotalRows = worksheet.addRow(["", "", "", "", "", "", "", "", "", "", "Labor Total:", { formula: formula.slice(0, -2) }]);

    generateWhiteSpace(worksheet);
}

function generateQuantitiesData(worksheet, quantitiesData, start_date, end_date) {
    generateQuantitiesHeader(worksheet);
    generateQuantitiesColumns(worksheet);
    var currentDate = new Date(start_date);
    var totals = [];

    while (currentDate <= end_date) {
        const currentDateString = currentDate.toISOString().split('T')[0];
        const currDateQuantities = quantitiesData.filter(entry => entry.date === currentDateString);

        if (currDateQuantities.length === 0) {
            currentDate.setDate(currentDate.getDate() + 1);
            continue;
        }

        let startingRow = worksheet.lastRow.number + 1;
        currDateQuantities.forEach((entry) => {
            let current_row = worksheet.lastRow.number + 1;
            let cost_code_name = entry.cost_code?.name;
            const rate = cost_code_map[cost_code_name.toUpperCase()];

            const addedRow = worksheet.addRow([
                current_row === startingRow ? currentDate.toISOString().split('T')[0] : "",
                cost_code_name,
                entry.quantity,
                rate,
                {
                    formula: `C${current_row} * D${current_row}`
                }
            ]);
        });
        const endingRow = worksheet.lastRow.number;
        const totalRows = worksheet.addRow(["", "", "", "Day Total:", { formula: `=SUM(E${startingRow}:E${endingRow})`}]);
        totals.push(worksheet.lastRow.number);

        generateWhiteSpace(worksheet);

        currentDate.setDate(currentDate.getDate() + 1);
    }
    var formula = "=";
    totals.forEach((row) => { formula += `E${row} + `; });
    const subtotalRows = worksheet.addRow(["", "", "", "Quantities Total:", { formula: formula.slice(0, -2) }]);

    generateWhiteSpace(worksheet);
}

function generateEquipmentData(worksheet, equipmentData, start_date, end_date) {
    generateEquipmentHeader(worksheet);
    generateEquipmentColumns(worksheet);
    var currentDate = new Date(start_date);
    var totals = [];

    while (currentDate <= end_date) {
        const currentDateString = currentDate.toISOString().split('T')[0];
        const currDateEquipment = equipmentData.filter(entry => entry.date === currentDateString);

        if (currDateEquipment.length === 0) {
            currentDate.setDate(currentDate.getDate() + 1);
            continue;
        }

        let startingRow = worksheet.lastRow.number + 1;
        currDateEquipment.forEach((entry) => {
            let current_row = worksheet.lastRow.number + 1;
            let cost_code_name = entry.cost_code?.name;
            const rate = cost_code_map[cost_code_name.toUpperCase()];
            
            const quantity = extractNumberFromString(entry.notes);

            const addedRow = worksheet.addRow([
                current_row === startingRow ? currentDate.toISOString().split('T')[0] : "",
                cost_code_name,
                quantity,
                rate,
                {
                    formula: `C${current_row} * D${current_row}`
                }
            ]);      
        });
        const endingRow = worksheet.lastRow.number;
        const totalRows = worksheet.addRow(["", "","", "Day Total:", { formula: `=SUM(E${startingRow}:E${endingRow})`}]);
        totals.push(worksheet.lastRow.number);

        generateWhiteSpace(worksheet);

        currentDate.setDate(currentDate.getDate() + 1);
    }
    var formula = "=";
    totals.forEach((row) => { formula += `E${row} + `; });
    const subtotalRows = worksheet.addRow(["", "", "", "Equipment Total:", { formula: formula.slice(0, -2) }]);

    generateWhiteSpace(worksheet);
}

function generateLaborColumns(worksheet) {
    const row = worksheet.addRow(["Date", "Name", "Title", "Time-In", "Time-Out", "Reg Hours", "Reg Rate", "OT Hrs", "Premium Hrs", "Travel Hrs", "Total Hrs", "Total Charge"]);
    LABOR_COLUMN_WIDTHS.forEach((width, index) => {
        worksheet.getColumn(index + 1).width = width;
    });

    for (let i = 1; i <= row.cellCount; i++) {
        const cell = worksheet.getCell(2, i);
        formatCell(cell, LABOR_COLUMN_TYPE, LABOR_COLUMN_PATTERN, LABOR_COLUMN_COLOR, LABOR_COLUMN_ALIGNMENT, ALL_OUTLINE, ALL_OUTLINE_COLOR);
    }
}

function generateQuantitiesColumns(worksheet) {
    const row = worksheet.addRow(["Date", "Cost Code", "Quantity", "Rate", "Total"]);
    // Format the rows here as needed
}

function generateEquipmentColumns(worksheet) {
    const row = worksheet.addRow(["Date", "Cost Code", "Quantity", "Rate", "Total"]);
    // Format the rows here as needed
}

function generateLaborHeading(worksheet) {
    const newRow = worksheet.addRow(["Labor"]);
    worksheet.mergeCells(`A${newRow.number}:L${newRow.number}`);
    const mergedCell = worksheet.getCell(`A${newRow.number}`);
    formatCell(mergedCell, LABOR_HEADING_TYPE, LABOR_HEADING_PATTERN, LABOR_HEADING_COLOR, LABOR_HEADING_ALIGNMENT, ALL_OUTLINE, ALL_OUTLINE_COLOR);
}

function generateQuantitiesHeader(worksheet) {
    const newRow = worksheet.addRow(["Quantities"]);
    worksheet.mergeCells(`A${newRow.number}:E${newRow.number}`);
    const mergedCell = worksheet.getCell(`A${newRow.number}`);
    formatCell(mergedCell, LABOR_HEADING_TYPE, LABOR_HEADING_PATTERN, LABOR_HEADING_COLOR, LABOR_HEADING_ALIGNMENT, ALL_OUTLINE, ALL_OUTLINE_COLOR);
}

function generateEquipmentHeader(worksheet) {
    const newRow = worksheet.addRow(["Equipment"]);
    worksheet.mergeCells(`A${newRow.number}:E${newRow.number}`);
    const mergedCell = worksheet.getCell(`A${newRow.number}`);
    formatCell(mergedCell, LABOR_HEADING_TYPE, LABOR_HEADING_PATTERN, LABOR_HEADING_COLOR, LABOR_HEADING_ALIGNMENT, ALL_OUTLINE, ALL_OUTLINE_COLOR);
}

function generateWhiteSpace(worksheet) {
    worksheet.addRow([""]);
}

function formatCell(cell, type, pattern, color, alignment, style, outline_color) {
    cell.border = {
        top: { style: style, color: { argb: outline_color } },
        left: { style: style, color: { argb: outline_color } },
        bottom: { style: style, color: { argb: outline_color } },
        right: { style: style, color: { argb: outline_color } }
    };
    cell.fill = {
        type: type,
        pattern: pattern,
        fgColor: { argb: color }
    };
    cell.alignment = { vertical: alignment.vertical, horizontal: alignment.horizontal };
}