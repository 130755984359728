import { Form, Input, message } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import ButtonComponent from "../../components/fields/button";
import { createEquipmentApi } from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";

export default function Addequipment({
  initialValues = {},
  type,
  handleDrawerOpenClose,
  handleSuccess,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    console.log(values,"value")
    setLoading(true);
    if (type === "add") {
      const res = await createEquipmentApi();
      if (res?.status === 200) {
        message.success(res?.message || "Vehicle create successfully");
        handleSuccess();
        handleDrawerOpenClose();
      }
      else{
        errorMessage(res)
      }
    }
    setLoading(false);
  };
  return (
    <FormContainer
      form={form}
      initialValues={initialValues}
      layout="vertical"
      // autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item
        label="Name"
        name="equipment name"
        rules={[
          {
            required: true,
            // max: 3,
          },
        ]}
      >
        <Input placeholder="Enter value" size="large" />
      </Form.Item>
      <Form.Item
        label="Equipment Type"
        name="equipmenttype"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Enter value" size="large" />
      </Form.Item>
      <div className="button-wrapper">
        <ButtonComponent
          text={type === "add" ? "Submit" : "Update"}
          htmlType="Submit"
          loading={loading}
        />
      </div>
    </FormContainer>
  );
}
const FormContainer = styled(Form)`
  .button-wrapper {
    text-align: right;
  }
`;
