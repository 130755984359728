import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllNotificationUsers } from "../../services/Api/collection";
import { Button, Select, Input } from "antd";
import { pushNotification } from "../../services/Api/collection";

const EquipmentWrapper = styled.div`
  padding: 20px;
`;

const SelectWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const AddAllButton = styled(Button)`
  position: relative;
  bottom: -10px;
  background-color: white;
  color:grey;
  right: ;
  z-index: 1;
`;

const { Option } = Select;
const { TextArea } = Input;

export const NotificationCenter = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const getUsers = async () => {
    try {
      const res = await getAllNotificationUsers();
      console.log("API response:", res);
      if (res?.status === 200) {
        const data = res?.data.data;
        if (Array.isArray(data)) {
          console.log("Fetched users data:", data);
          setUsers(data);
          setSelectedUserIds(data.map(user => user.userId)); // Select all by default
        } else {
          console.error("Data is not an array:", data);
        }
      } else {
        console.error("Error fetching users:", res);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleSelectionChange = (selectedIds) => {
    setSelectedUserIds(selectedIds);
  };

  const addAllUsers = () => {
    const allUserIds = users.map(user => user.userId);
    setSelectedUserIds(allUserIds);
  };

  const printSelectedUserIds = () => {
    console.log("Selected User IDs:", selectedUserIds);
  };

  const handleSubmit = () => {
    const notificationData = {
      title,
      body,
      users: selectedUserIds
    };
    pushNotification(notificationData);
    // Add your submit logic here
  };

  return (
    <EquipmentWrapper>
      <div className="heading-section">
        <p>Notification Center</p>
      </div>
      <Input 
        placeholder="Title" 
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      <TextArea 
        placeholder="Body" 
        value={body}
        onChange={(e) => setBody(e.target.value)}
        style={{ marginBottom: '20px' }}
        rows={4}
      />
      {Array.isArray(users) && users.length > 0 ? (
        <SelectWrapper>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select users"
            value={selectedUserIds}
            onChange={handleSelectionChange}
          >
            {users.map(user => (
              <Option key={user.userId} value={user.userId}>
                {user.name}
              </Option>
            ))}
          </Select>
          <AddAllButton type="primary" onClick={addAllUsers}>
            Add All
          </AddAllButton>
        </SelectWrapper>
      ) : (
        <p>No users available</p>
      )}
      <Button type="primary" onClick={handleSubmit}>
        Submit Notification
      </Button>
    </EquipmentWrapper>
  );
};

export default NotificationCenter;
