
import React from "react";
import styled from "styled-components";


export default function ViewDetails({vehicle}) {

 const vehicledata=vehicle?.vehiclesData
 const projectmember=vehicle?.projectsMember
const EquipmentsData=vehicle?.EquipmentsData

  return (
    
    <ProjectView>
      <div className="heading">Disaster Project</div>
      <ImageFlex>
        {/* {imageList?.map((list, index) => (
          <Image height={"80px"} src={list?.src} key={index} />
        ))} */}
      </ImageFlex>
      <div className="section-heading">Assign member:-</div>
      {projectmember?.map((e)=>(
        <div className="member-list list">
        <div>{e?.name}</div>
        <div>{e?.email_address}</div>
        {/* <DeleteOutlined /> */}
      </div>
      ))}
      
      <div className="section-heading">Assign Vehicle:--</div>
      <div className="vehicle-container">
        {vehicledata?.map((list) => (
          <div className="list">
            <div>{list?.vehicleName}</div>
            <div>{list?.vehiclesNumber}</div>
            {/* <div> */}
            {/* <DeleteOutlined /> */}
            {/* </div> */}
          </div>
        ))}
      </div>
      <div className="section-heading">Equipment Assign</div>
      {
        EquipmentsData?.map((list)=>(
          <div className="list">
            <div>{list?.equipment}</div>
            <div>{list?.make}</div>

          </div>
        ))
      }
    </ProjectView>
    
  );
}

const ProjectView = styled.div`
  .heading {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
  }
  .list {
    display: flex;
    gap: 30px;
    justify-content: space-between;
  }
  .section-heading {
    font-weight: bold;
    margin-top: 10px;
  }
`;

const ImageFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
