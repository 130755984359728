import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { sidebarList } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
// import { MenuIcon } from "../../utils/svgIcons";
import { activeRoute } from "../../utils/commonMethod";
import { defaultTheme } from "../../utils/theme";
import { useSelector } from "react-redux";
import signInimg from "../../utils/restorefastLogoBlue.png"

export default function Sidebar() {
  const [selectedSection, setSelectedSection] = useState(); //"User Management"
  const navigate=useNavigate()
  useEffect(() => {
    const selectedList = activeRoute();
    setSelectedSection(selectedList);
    const handlePopstate = () => {
      const selectedList = activeRoute();
      setSelectedSection(selectedList);
    };
    window.addEventListener("popstate", handlePopstate);
    // window.addEventListener("beforeunload", handlePopstate);
    return () => window.removeEventListener("popstate", handlePopstate);
  }, []);

  const handleListClick = (selectedList) => setSelectedSection(selectedList);
  const loadingdata=useSelector((e)=>e?.Loadingdata?.data )
  console.log(loadingdata,"loading")
  return (
    <SidebarWrapper>
      <LogoText>
      <img src={signInimg} width="200" alt="Logo" />

      </LogoText>
      {sidebarList?.map((list, index) => (
        <div key={index} onClick={() =>{ 
          if(!loadingdata){

            handleListClick(list?.name)
          }
          }}>

          <ListItem   onClick={()=>{if(!loadingdata){

            navigate(list?.link)
          }
          }
          }
             activeitem={selectedSection === list?.name}>
            {list.icon}
            {list.name}
          </ListItem>
        </div>
      ))}
    </SidebarWrapper>
  );
}

const SidebarWrapper = styled.div`
  height: 100%;
  background: ${({ theme }) => theme?.primaryColor};
  font-family: ${({ theme }) => theme?.fontFamily};
`;

const LogoText = styled.div`
  font-weight: 900;
  text-align: center;
  font-size: 22px;
  padding: 18px 10px;
  border-bottom: 1px solid white;
  border-width: 2px;
  margin-bottom: 30px;
  background: white;
  position: relative;
  color: ${({ theme }) => theme?.primaryColor};
  span {
    color: black;
  }
  .drawer-icon {
    position: absolute;
    right: -10px;
    top: 24px;
  }
`;

const ListItem = styled.div`
  padding: 8px 14px;
  margin: 0 9px 8px 9px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  background: ${(props) =>
    props?.activeitem ? defaultTheme?.white : "transparent"};
  color: ${(props) =>
    props?.activeitem ? defaultTheme?.primaryColor : "white"};
  &:hover {
    background: ${({ theme }) => theme?.white};
    color: ${({ theme }) => theme?.primaryColor};
    svg {
      fill: ${({ theme }) => theme?.primaryColor};
    }
  }
  svg {
    fill: ${(props) =>
      props?.activeitem ? defaultTheme?.primaryColor : "white"};
    height: 18px;
    width: 18px;
  }
  text-decoration: none;
`;
