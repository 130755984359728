import React, { useEffect, useState } from "react";
import {  message, Spin } from "antd";

import { useLocation, useNavigate } from "react-router-dom";
import { signInApi } from "../../services/Api/collection";
import { authlogin } from "../../services/redux/signIn";
import { useDispatch } from "react-redux";
import signInimg from "../../utils/image 9.png"

import styled from "styled-components";
import { refress } from "../../services/redux/Refresstoken";

import restoreFastLogo from "../../utils/restorefast.png"
const SignInForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    console.log(values,"vvvv");
    
    setLoading(true);
    const res = await signInApi(values);
    if (res?.status === 200) {
  
      const result = { loginUser: res?.data, token: res?.token?.Token,accesstoken:res?.token };
      message.success(res?.message || "Signup Successfully");
      console.log(result, "result");
      dispatch(authlogin(result));
      dispatch(refress(res?.token?.refresh_token))
      navigate("/users");
    } else message.error(res?.message || "Something went wrong");
    setLoading(false);
  };

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  // const token = ""; //const isAutharised = useSelector((state) => state?.?.data?.password);
  // const token =
  //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTRjZTM2YjQwNjk2YWM2ODA4MmZlMTgiLCJpYXQiOjE2OTk1OTQxMzF9.V7vdjYp1_kPTZBfKfJVbJzZWyNEMq6d0VQTWTcUwwP4";
console.log("++params",params);
  useEffect(() => {
    console.log(params, "pppp");
    if (params.has("token")) {
      dispatch(
        authlogin({
          token: params.get("token"),
        })
      );
    }
    if (params.has("code")) {
      onFinish({
        code: params.get("code"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  return (
    <Spin spinning={loading}>
      <Signinwrapper>
       
        <div className="sigin-btn">
         <div className="logo">
         <img src={restoreFastLogo} width="350" alt="" class="loginLogo" />
          {/*   PROD/DEV/LOCAL SWITCH  */}
         </div>
           {/* <a  href="https://login-sandbox.procore.com/oauth/authorize?response_type=code&client_id=a17012287f80f65588f42d25487cb79b88a67a469691b06cb840c6148b6df7e8&redirect_uri=http://localhost:3000/"> */}
          {/* <a  href="https://login-sandbox.procore.com/oauth/authorize?response_type=code&client_id=a17012287f80f65588f42d25487cb79b88a67a469691b06cb840c6148b6df7e8&redirect_uri=https://devadmin.restorefastdataendpoint.com/"> */}
          {/* <a  href="https://login.procore.com/oauth/authorize?response_type=code&client_id=e80d0faa29e67fcba9c8078727298309a23bcc0a30aeaf9031e7d40da19169fa&redirect_uri=http://localhost:3000/">  */}
          <a  href="https://login.procore.com/oauth/authorize?response_type=code&client_id=e80d0faa29e67fcba9c8078727298309a23bcc0a30aeaf9031e7d40da19169fa&redirect_uri=https://admin.restorefastdataendpoint.com/"> 

          <button className="login-btn" >
            <img src={signInimg} alt="" />
            <p> Sign in with Procore</p>
           </button>
          </a>
        </div>
      </Signinwrapper>

     
    </Spin>
  );
};
export default SignInForm;
const Signinwrapper = styled.div`
  width: 100%;
  display: flex;
  height:100vh;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #123286 0%, #ED722E 100%);

  .image-box {
    width: 40%;
    img {
      width: 100%;
      height: 100vh;
    }
  }
  .sigin-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:12px;
    flex-direction: column;
  }
  .loginLogo{

  }
 
  .heading {
    color: white;
    text-align:center;
    font-family: Inter;
    font-size: 40px;
    font-style: italic;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .login-btn {
    display:flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    border-radius: 40px;
    background: #ed712e;
    box-shadow: 4px 4px 40px -5px rgba(0, 0, 0, 0.1);
    color: #fff;
    width: 310px;
    height: 60px;
    border: none;
    color: #fff;    
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;
