import React, { useState } from "react";
import { Form, Input, message } from "antd";
import ButtonComponent from "../../components/fields/button";
import styled from "styled-components";
import {
  addTeamMemberApi,
  editTeamMemberApi,

} from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";

export default function TeamMemberForm({
  initMemberList,
  type,
  handleMemberDrawer,
  handleSuccess,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);


  const onFinish = async (values) => {
    setLoading(true);
    if (type === "add") {
      const res = await addTeamMemberApi({
        ...values,
        // teamId: initMemberList.id,
      });
      if (res?.status === 200) {
        message.success(res?.message || "Member Add successfully");
        form.resetFields();
        handleSuccess();
        handleMemberDrawer();
      } else errorMessage(res);
    } else {
      const res = await editTeamMemberApi({
        ...values,
        memberId: initMemberList._id,
      });
      if (res?.status === 200) {
        message.success(res?.message || "Member Edit successfully");
        form.resetFields();
        handleSuccess();
        handleMemberDrawer();
      } else errorMessage(res);
    }
    setLoading(false);
  };

  return (
    <MemberWrapper>
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        initialValues={initMemberList}
      >
        <Form.Item
          label="Member Role"
          name="Role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter value" type="text" />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter value" type="text" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter value" type="email" />
        </Form.Item>
        <Form.Item
          label="Office"
          name="office"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter value" type="text" />
        </Form.Item>
        <Form.Item
          label="PhoneNo"
          name="phoneNumber"
          rules={[
            {
              required: true,
              pattern: new RegExp(/^[0-9]+$/),
              message: "enter only numbers",
            },
          ]}
        >
          <Input placeholder="Enter value" type="text" />
        </Form.Item>
        <div className="button-wrapper">
          <ButtonComponent
            text={type === "add" ? "Add" : "Update"}
            htmlType="Submit"
            loading={loading}
          />
        </div>
      </Form>
    </MemberWrapper>
  );
}

const MemberWrapper = styled.div`
  .list-wrapper {
    border: 1px solid grey;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 20px;
    max-height: 300px;
    overflow: auto;
  }
  .list-heading {
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 20px;
  }
  .list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    cursor: pointer;
  }
`;
// const
