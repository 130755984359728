import styled from "styled-components";

export const OuterContainer = styled.div`
  padding: 30px;
  background: #f1f1f1;
  height: calc(100% - 130px);
  overflow: auto;
  .heading-section {
    font-weight: 600;
    margin: 20px 0;
  }
  @media(max-width:1050px){
    padding:10px;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
`;
