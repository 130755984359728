/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import SearchField from "../../components/fields/searchField";

import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";

import DrawerComponent from "../../components/drawer";
import ModalComponent from "../../components/modal";
import ConfirmModal from "../../components/modal/confirmModal";
import VehicleForm from "./vehicleForm";
import {
  deleteVehicleApi,
  getVehicleListApi,
  searchVehicleApi,
} from "../../services/Api/collection";
import { message } from "antd";
import { errorMessage } from "../../utils/commonMethod";

const limit = 10;
export const Vehicle = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [openDrawer, setOpenDrawer] = useState({ status: false });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectUser, setSelectedUser] = useState({});
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ make: "", model: "", year: "" });

  const handleSearch = (searchText) => {
    setCurrentPage(1);
    setSearchText(searchText);
  };

  const handleGetVehicleData = async () => {
    setLoading(true);
    let res = await getVehicleListApi(currentPage, limit);
    if (res?.status === 200) {
      const { totalcount = 0, vehicleList = [] } = res?.data || {};
      setData(vehicleList);
      setTotalCount(totalcount);
    } else errorMessage(res);
    setLoading(false);
  };

  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page);
  }, []);

  const handleDrawerOpenClose = (
    type = "add",
    title = "Add Vehicle",
    data = {}
  ) => {
    setOpenDrawer({ status: !openDrawer.status, type, title, data });
  };

  const handleSearchData = async (filterPath) => {
    setLoading(true);
    const res = await searchVehicleApi(
      searchText,
      currentPage,
      limit,
      filterPath
    );
    if (res?.status === 200) {
      const { totalcount = 0, vehicleData = [] } = res?.data || {};
      setTotalCount(totalcount);
      setData(vehicleData);
    } else errorMessage(res);
    setLoading(false);
  };

  useEffect(() => {
    let interval;
    
    const fetchData = () => {
      if (searchText || filters.make || filters.model || filters.year) {
        const path = `&make=${filters.make}&model=${filters.model}&year=${filters.year}`;
        handleSearchData(path);
      } else {
        handleGetVehicleData();
      }
    };

    fetchData(); 

    interval = setInterval(fetchData, 120000); // Set interval to call fetchData every 2 minutes

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [searchText, currentPage, filters]);


  const handleDeleteConfirm = async () => {
    const res = await deleteVehicleApi(selectUser?._id);
    if (res?.status === 200) {
      message.success(res?.message || "Delete vehicle successfully");
      handleGetVehicleData();
      setCurrentPage(1);
    } else errorMessage(res);
    setOpenDeleteModal((pre) => !pre);
  };

  const closeOpenDeleteModal = () => setOpenDeleteModal((pre) => !pre);

  const columns = [
    {
      title: "Name",
      dataIndex: "vehicleName",
      sorter: (a, b) => a.vehicleName.localeCompare(b.vehicleName),
      key: "vehicleName",
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehiclesNumber",
      sorter: (a, b) => a.vehiclesNumber.localeCompare(b.vehiclesNumber),
      key: "vehicleNumber",
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: "Address",
      dataIndex: "addressLocation",
      key: "addressLocation",
      render: (_, record) => (
        <>
          {record?.addressLocation?.street},{record?.addressLocation?.city},
          {record?.addressLocation?.state},{record?.addressLocation?.country}
        </>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <>
          {record?.status ? (
            <>
              <USEbutton style={{ background: "red" }}>Inuse</USEbutton>
            </>
          ) : (
            <USEbutton style={{ background: "green" }}>Free</USEbutton>
          )}
        </>
      ),
    },
  ];

  const handleSuccess = () => {
    if (searchText) handleSearchData();
    else handleGetVehicleData();
  };

  return (
    <VehicleWrapper>
      <div className="heading-section">Vehicle</div>
      <div className="filters">
        <div className="search-wrapper">
          <SearchField handleSearch={handleSearch} />
        </div>

      </div>
      <div className="filters filter-dropdown">
      </div>
      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
      {openDrawer?.status && (
        <DrawerComponent
          open={openDrawer?.status}
          handleClose={handleDrawerOpenClose}
          title={openDrawer?.title || "Add Vehicle"}
        >
          <VehicleForm
            type={openDrawer?.type || "add"}
            handleDrawerOpenClose={handleDrawerOpenClose}
            initialValues={openDrawer?.data}
            handleSuccess={handleSuccess}
          />
        </DrawerComponent>
      )}
      {openDeleteModal && (
        <ModalComponent
          openModal={openDeleteModal}
          setOpenModal={closeOpenDeleteModal}
        >
          <ConfirmModal
            handleConfirm={handleDeleteConfirm}
            handleCancel={closeOpenDeleteModal}
          />
        </ModalComponent>
      )}
    </VehicleWrapper>
  );
};

const VehicleWrapper = styled(OuterContainer)`
  .filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  .filter-dropdown {
    justify-content: flex-end;
  }

  .search-wrapper {
    flex: 1;
  }
`;
const USEbutton = styled.button`

font-size:14px,
padding:5px,
border-radius:5px;
border:none;
color:white;

`;
