import { createSlice } from "@reduxjs/toolkit";
const initialState={
    data:[]
}
const Refresstoken=createSlice({
    name:"Refresstoken",
    initialState,
    reducers:{
        refress:(state,action)=>{
            state.data=action.payload
        }
    }
})
export const {refress}=Refresstoken.actions;
export default Refresstoken.reducer;