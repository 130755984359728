import React, { memo } from "react";
import { Table } from "antd";

const TableComponent = ({ columns, data, loading = false }) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ x: true }}
      loading={loading}

    />
  );
};
export default memo(TableComponent);
