export const LABOR_HEADING_COLOR = 'FFADD8E6';
export const LABOR_HEADING_PATTERN = 'solid';
export const LABOR_HEADING_TYPE = 'pattern';
export const LABOR_HEADING_ALIGNMENT = { vertical: 'middle', horizontal: 'center' };

export const LABOR_COLUMN_WIDTHS = [15, 50, 20, 15, 15, 10, 10, 10, 15, 15, 15, 15]

export const LABOR_COLUMN_COLOR = 'FFD3D3D3';
export const LABOR_COLUMN_PATTERN = 'solid';
export const LABOR_COLUMN_TYPE = 'pattern';
export const LABOR_COLUMN_ALIGNMENT = { vertical: 'middle', horizontal: 'center' };

export const ALL_OUTLINE = 'thin';
export const ALL_OUTLINE_COLOR = '00000000';