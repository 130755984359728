import React, { useCallback, useEffect, useState } from "react";
import ButtonComponent from "../../components/fields/button";
import styled from "styled-components";
import { ActionWrapper, OuterContainer } from "../style";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import { DeleteOutlined, EditOutlined, RightOutlined } from "@ant-design/icons";
import DrawerComponent from "../../components/drawer";
import SearchField from "../../components/fields/searchField";
import ModalComponent from "../../components/modal";
import ConfirmModal from "../../components/modal/confirmModal";
import { Tooltip, message } from "antd";
import TeamMemberForm from "./teamMemberForm";
import { useLocation, useNavigate } from "react-router-dom";
import { defaultTheme } from "../../utils/theme";
import {
  deleteMemberApi,
  getTeamMemberApi,
  searchMemberApi,
} from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";
import { useSelector } from "react-redux";

let limit = 10;

export default function TeamMembers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [openMemberDrawer, setOpenMemberDrawer] = useState({
    status: false,
    type: "",
    title: "",
    data: {},
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const { teamData } = useLocation()?.state || {};
  const procoretoken = useSelector(
    (e) => e?.signIn?.data?.accesstoken?.accessToken
  );
  
  console.log(procoretoken,"ppp")

  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page);
  }, []);

  const openDeleteConfirmModal = (teamList) => {
    closeOpenDeleteModal();
    setUserInfo(teamList);
  };
 
  const getTeamMemberList = async () => {
    setLoading(true);
    let res = await getTeamMemberApi(
      teamData?._id,
      currentPage,
      limit,
      procoretoken
    );
    if (res?.status === 200) {
      const { data, Countdata } = res || {};
      setData(data);
      setTotalCount(Countdata?.total_Members ?? 0);
    } else errorMessage(res);
    setLoading(false);
  };

  const getTeamSearchData = async () => {
    setLoading(true);
    const res = await searchMemberApi(searchText, currentPage, limit);
    if (res?.status === 200) {
      const { locationListing, totalCount } = res?.data || {};
      setData(locationListing);
      setTotalCount(totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchText) getTeamSearchData();
    else getTeamMemberList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText]);

  const columns = [
    {
      title: "Role",
      dataIndex: "Role",
      key: "Role",
    },
    {
      title: "Name",
      dataIndex: "name",
      // sorter: (a, b) => a.name.localeCompare(b.name),
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Office",
    //   dataIndex: "office",
    //   key: "office",
    // },
    {
      title: "Phone No",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <ActionWrapper>
          <Tooltip
            title="delete Team"
            onClick={() => openDeleteConfirmModal(record)}
          >
            <DeleteOutlined />
          </Tooltip>
          <Tooltip
            title="edit Team member"
            onClick={() =>
              handleDrawerOpenClose("edit", "Edit Team Member", record)
            }
          >
            <EditOutlined />
          </Tooltip>
        </ActionWrapper>
      ),
    },
  ];

  const handleDrawerOpenClose = (type, title, data = {}) => {
    setOpenMemberDrawer({
      status: !openMemberDrawer?.status,
      type,
      title,
      data,
    });
  };

  const handleSearch = (searchText) => {
    setCurrentPage(1);
    setSearchText(searchText);
  };

  const handleDeleteConfirm = async () => {
    closeOpenDeleteModal();
    const res = await deleteMemberApi(userInfo?._id);
    if (res?.status === 200) {
      message.success(res?.message || "Member Delete successfully");
      setCurrentPage(1);
      currentPage === 1 ? getTeamMemberList() : setCurrentPage(1);
    } else errorMessage(res);
  };

  const closeOpenDeleteModal = () => setOpenDeleteModal((pre) => !pre);

  const handleMemberDrawer = (data = {}) =>
    setOpenMemberDrawer({ status: !openMemberDrawer?.status, data });

  const navigate = useNavigate();
  const handleNavigate = () => navigate("/members");

  return (
    <TeamWrapper>
      <div className="heading-section">
        <span className="parent-root" onClick={handleNavigate}>
          Team
        </span>
        <RightOutlined />
        Team Members ({teamData?.teamName})
      </div>
      <div className="filters">
        <div className="search-wrapper">
          <SearchField handleSearch={handleSearch} />
        </div>

        <ButtonComponent
          text="Add Member"
          onClick={() =>
            handleDrawerOpenClose("add", "Add Team Member", {
              id: teamData._id,
            })
          }
        />
      </div>
      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
      {openMemberDrawer?.status && (
        <DrawerComponent
          open={openMemberDrawer?.status}
          handleClose={handleMemberDrawer}
          title={openMemberDrawer?.title || "Add Team Members"}
        >
          <TeamMemberForm
            initMemberList={openMemberDrawer?.data}
            type={openMemberDrawer?.type}
            handleMemberDrawer={handleMemberDrawer}
            handleSuccess={getTeamMemberList}
          />
        </DrawerComponent>
      )}
      {openDeleteModal && (
        <ModalComponent
          openModal={openDeleteModal}
          setOpenModal={closeOpenDeleteModal}
        >
          <ConfirmModal
            handleConfirm={handleDeleteConfirm}
            handleCancel={closeOpenDeleteModal}
          />
        </ModalComponent>
      )}
    </TeamWrapper>
  );
}

const TeamWrapper = styled(OuterContainer)`
  .filters {
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
    gap: 10px;
  }
  .search-wrapper {
    flex: 1;
  }
  .parent-root {
    color: ${defaultTheme?.lightText};
    cursor: pointer;
  }
`;
