import React, { memo } from "react";
import styled from "styled-components";
import ButtonComponent from "../fields/button";
import { DeleteOutlined } from "@ant-design/icons";

const ConfirmModal = ({
  subheading,
  handleConfirm,
  handleCancel,
  confirmBtnText,
  icon,
}) => (
  <ConfirmWrapper>
    {icon ?? <DeleteOutlined />}
    <div className="heading">Are you sure?</div>
    <div className="sub-heading">
      {subheading || "Do you really want to delete this Records?."}
    </div>
    <div className="button-wrapper">
      <ButtonComponent text="Cancel" onClick={handleCancel} />
      <ButtonComponent
        text={confirmBtnText || "Delete"}
        onClick={handleConfirm}
      />
    </div>
  </ConfirmWrapper>
);

export default memo(ConfirmModal);

const ConfirmWrapper = styled.div`
  text-align: center;
  .heading {
    font-weight: 800;
    font-size: 22px;
    margin: 10px 0;
  }
  .button-wrapper {
    display: flex;
    gap: 10px;
    margin: 15px 5px;
    justify-content: end;
  }
  svg {
    height: 40px;
    width: 40px;
  }
`;
