import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonComponent from "../../components/fields/button";
import SelectComponent from "../../components/fields/select";
import {
  allfreeEquipmentlist,
  getMemberApi,
  getMemberSubmit,
  getfreevehiclelist,
} from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";



const ProjectForm = ({
  initialValues = {},
  type,
  handleDrawerOpenClose,
  handleSuccess,
  projectInfo,
  handleClose,
  update

}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [filterMember, setFilterMembers] = useState([]);
  const [filtercar, setfiltercar] = useState([]);
  const [filterequipment, setfilterequipment] = useState([]);
  console.log(projectInfo,"project");

  const memberDroplistFilter = (list) => {
    // let memberSelectList = [];
    const newList = list.map((e) => {
      return {
        label: e.name,
        value: e.procoreUserId,
      };
    });
    console.log("++result", newList);
    setFilterMembers(newList);
  };

  const equipmentsdroplistfilter=(list)=>{
    const newList=list?.map((e)=>{
      return{
        label: e.equipment
        ,
        value: e._id,

      }
    })
    setfilterequipment(newList)
  }
  const getequipmentlist=async()=>{
    const res=await allfreeEquipmentlist();
    if(res?.status ===200){
      equipmentsdroplistfilter(res?.data?.findFreeEquipments ??[])
    }
    else{

    }
  }

  const getfiltermembers = async () => {
    const res = await getMemberApi();
    if (res?.status === 200) {
      console.log(res);
      memberDroplistFilter(res?.data ?? []);
    }
  };
  const carDroplistFilter = (list) => {
    const newlist = list?.map((e) => {
      return {
        label: e?.vehicleName,
        value: e?._id
      };
    });
    console.log(newlist,"newlist")
    setfiltercar(newlist);
  };

  const getfiltervehicle = async () => {
    const res = await getfreevehiclelist();
    if (res?.status === 200) {
      console.log(res);
      carDroplistFilter(res?.data ?? []);
    } else {
    }
  };

  useEffect(() => {
    getfiltermembers();
    getfiltervehicle();
    getequipmentlist();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
  
    
    const payload = {
      memberId: values?.member,
      requestId: projectInfo?._id,
      equipmentId: values?.Equipment,
      project_id: projectInfo?.project_id

    };
    if(values?.vehicle){
      payload["vehicleId"]=values?.vehicle
    }
    console.log(payload, "payload");
    const res = await getMemberSubmit(payload);
    if (res?.status === 200) {
      setLoading(false);
      message.success(res?.message || "Successfully Sumbit");
      handleClose();
      update()
    } else {
      setLoading(false);
      errorMessage(res);
    }
  };

  const handleSelectValue = (selectData,filedname) => {
    console.log(selectData,filedname, "select")
  if(filedname==="member"){

    form.setFieldsValue({ member: selectData });
  }
  if(filedname==="Equipment"){
    form.setFieldsValue({ Equipment: selectData });

  }
  if(filedname==="vehicle"){

    form.setFieldsValue({ vehicle: selectData });
  }
  };


  return (
    <FormContainer
      //   name="trigger"
      //   style={{
      //     maxWidth: 600,
      //   }}
      form={form}
      initialValues={initialValues}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item
        label="Member"
        name="member"
        // rules={[
        //   {
        //     required: true,
        //   },
        // ]}
      >
        <SelectComponent
          handleSelectValue={(value)=>handleSelectValue(value,"member")}
          placeholder="Member"
          options={filterMember}
        />
      </Form.Item>
      <Form.Item
        label="Vehicle"
        name="vehicle"
        // rules={[
        //   {
        //     required: true,
        //   },
        // ]}
      >
        <SelectComponent
          handleSelectValue={(value)=>handleSelectValue(value,"vehicle")}
          placeholder="vehicle"
          multiple
          options={filtercar}
        />
      </Form.Item>
      <Form.Item
        label="Equipment"
        name="Equipment"
        // rules={[
        //   {
        //     required: true,
        //   },
        // ]}
      >
        <SelectComponent
          handleSelectValue={(value)=>handleSelectValue(value,"Equipment")}
          placeholder="Equipment"
          multiple
          options={filterequipment}
        />
      </Form.Item>

      <div className="button-wrapper">
        <ButtonComponent text="Submit" htmlType="Submit" loading={loading} />
      </div>
    </FormContainer>
  );
};
export default ProjectForm;

const FormContainer = styled(Form)`
  .button-wrapper {
    text-align: right;
  }
`;
