import React from "react";
import { Modal } from "antd";

const ModalComponent = (props) => {
  const { openModal, setOpenModal, children } = props;

  const handleOk = () => {
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };
  
  return (
    <>
      <Modal
        open={openModal}
        // title={title || "Title"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => <></>}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalComponent;
