import React, { useCallback, useState } from "react";
import { Form, Input, message } from "antd";
import ButtonComponent from "../../components/fields/button";
import styled from "styled-components";
import { errorMessage } from "../../utils/commonMethod";
import SelectComponent from "../../components/fields/select";
import {
  createVehicleApi,
  editVehicleApi,
} from "../../services/Api/collection";

const make = [
  {
    label: "Tata",
    value: "Tata",
  },
  {
    label: "Hundai",
    value: "Hundai",
  },
  {
    label: "Maruti",
    value: "Maruti",
  },
  {
    label: "Mahindra & Mahindra",
    value: "Mahindra & Mahindra",
  },
  {
    label: "Volkswagen",
    value: "Volkswagen",
  },
];

const model = {
  Tata: [
    {
      label: "abc",
      value: "abc",
    },
    {
      label: "xyz",
      value: "xyz",
    },
  ],
  "Mahindra & Mahindra": [
    {
      label: "abc1",
      value: "abc1",
    },
    {
      label: "xyz1",
      value: "xyz1",
    },
  ],
  Hundai: [
    {
      label: "abc2",
      value: "abc2",
    },
    {
      label: "xyz2",
      value: "xyz2",
    },
  ],
  Maruti: [
    {
      label: "abc3",
      value: "abc3",
    },
    {
      label: "xyz3",
      value: "xyz3",
    },
  ],
  Volkswagen: [
    {
      label: "abc4",
      value: "abc4",
    },
    {
      label: "xyz4",
      value: "xyz4",
    },
  ],
};
const year = [
  {
    label: 1995,
    value: 1995,
  },
  {
    label: 1998,
    value: 1998,
  },
  {
    label: 2000,
    value: 2000,
  },
  {
    label: 2002,
    value: 2002,
  },
  {
    label: 2005,
    value: 2005,
  },
  {
    label: 2008,
    value: 2008,
  },
  {
    label: 2010,
    value: 2010,
  },
  {
    label: 2012,
    value: 2012,
  },
  {
    label: 2015,
    value: 2015,
  },
];

const VehicleForm = ({
  initialValues = {},
  type,
  handleDrawerOpenClose,
  handleSuccess,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const makeValue = Form.useWatch("make", form);

  const onFinish = async (values) => {
    setLoading(true);
    if (type === "add") {
      const res = await createVehicleApi(values);
      if (res?.status === 200) {
        message.success(res?.message || "Vehicle create successfully");
        handleSuccess();
        handleDrawerOpenClose(); // call after succesfully hit api
      } else errorMessage(res);
    } else {
      const res = await editVehicleApi(initialValues._id, values);
      if (res?.status === 200) {
        message.success(res?.message || "Vehicle edit successfully");
        handleSuccess();
        handleDrawerOpenClose(); // call after succesfully hit api
      } else errorMessage(res);
    }
    setLoading(false);
  };

  const handleSelectValue = useCallback((key, selectvalue) => {
    form.setFieldsValue({ [key]: selectvalue });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContainer
      //   name="trigger"
      //   style={{
      //     maxWidth: 600,
      //   }}
      form={form}
      initialValues={initialValues}
      layout="vertical"
      // autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item
        label="Name"
        name="vehicleName"
        rules={[
          {
            required: true,
            // max: 3,
          },
        ]}
      >
        <Input placeholder="Enter value" size="large" />
      </Form.Item>

      <Form.Item
        label="Vehicle Number"
       name="vehiclesNumber"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Enter value" size="large" />
      </Form.Item> 

      <Form.Item
        label="Make"
        name="make"
        // rules={[
        //   {
        //     required: true,
        //   },
        // ]}
      >
        <SelectComponent
          handleSelectValue={(e) => handleSelectValue("make", e)}
          defaultValue={initialValues?.make}
          placeholder="Make"
          options={make}
        />
      </Form.Item>
      <Form.Item
        label="Model"
        name="model"
        // rules={[
        //   {
        //     required: true,
        //   },
        // ]}
      >
        <SelectComponent
          handleSelectValue={(e) => handleSelectValue("model", e)}
          defaultValue={initialValues?.model}
          placeholder="Model"
          options={model[makeValue] || []}
        />
      </Form.Item>
      <Form.Item
        label="Year"
        name="year"
        // rules={[
        //   {
        //     required: true,
        //   },
        // ]}
      >
        <SelectComponent
          handleSelectValue={(e) => handleSelectValue("year", e)}
          defaultValue={initialValues?.year}
          placeholder="Year"
          options={year}
        />
      </Form.Item>
      <div className="button-wrapper">
        <ButtonComponent
          text={type === "add" ? "Submit" : "Update"}
          htmlType="Submit"
          loading={loading}
        />
      </div>
    </FormContainer>
  );
};
export default VehicleForm;

const FormContainer = styled(Form)`
  .button-wrapper {
    text-align: right;
  }
`;
