import React from "react";
import { Button, Form, Input, message } from "antd";
import { FormWrap, ProjectName, SignUpLink } from "../signin/style";
import { BtnWrap, LoginForm, Logintext } from "../signin/style";
import { Link, useNavigate } from "react-router-dom";
import { signUpApi } from "../../services/Api/collection";

const SignUpForm = () => {
  const navigate = useNavigate();
  const onFinish = async (values) => {
    const res = await signUpApi(values);
    if (res?.status === 200) {
      console.log("++response", res);
      message.success(res?.message || "Signup Successfully");
      navigate("/");
    } else message.error(res?.message || "Something went wrong");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <FormWrap>
      <ProjectName>
        Restore <span className="project-name">Fast</span>
      </ProjectName>
      <Form
        name="basic"
        initialValues={
          {
            // remember: true,
          }
        }
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{
          margin: "20px 0px",
        }}
      >
        <LoginForm>
          <Logintext>SignUp</Logintext>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input your first Name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input your last Name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <BtnWrap>
            <Button className="submit-btn" type="primary" htmlType="submit">
              Submit
            </Button>
          </BtnWrap>
          <SignUpLink>
            Already have an account?
            <Link to="/">SignIn</Link>
          </SignUpLink>
        </LoginForm>
      </Form>
    </FormWrap>
  );
};
export default SignUpForm;
