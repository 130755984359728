import React from 'react'
import styled from 'styled-components';
import { OuterContainer } from '../style';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const UserDetail = () => {
    const {state}=useLocation();
    const navigate=useNavigate();
    const backstyle={
        display:"flex",
        alignItems: "center",
        margin:"10px 0px",
        gap: "5px",
        color:"black",
        cursor: "pointer",

    }
    
    const users=state?.dataIndex?.usersData
    const renderValue = (value) => {
        if (typeof value === 'object') {
          // Recursively handle nested objects
          return JSON.stringify(value);
        }
        return value;
      };

   
   console.log(users,"users")
   
  return (
    <Wrapper>

    <div onClick={()=>navigate(-1)} style={backstyle}>
    <LeftOutlined />
        <p> Back</p>
    </div>

    <p> User Details</p>
    {users.map((user, index) => (
        <div key={index}>
          <ul>
            {Object.entries(user).map(([key, value]) => (
              <li key={key}>
                <strong>{key}:</strong> {renderValue(value)}
              </li>
            ))}
          </ul>
        </div>
      ))}

      
    </Wrapper>
  )
}

export default UserDetail;

const Wrapper=styled(OuterContainer)`


`

