import { LeftCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "../sidebar";

export default function Mobileheader() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const style={
    position:'absolute',
    zIndex:999,
    right:'-5%',
    top:'4%'


  }
  return (
    <Wrapper>
      <div>
        <MenuOutlined
          onClick={showDrawer}
          style={{
            fontSize: 20,
          }}
        />
      </div>

      <Drawer
        title="Basic Drawer"
        onClose={onClose}
        open={open}
        placement="left"
        width={250}

        prefixCls="mobileheader"
      >
        <div className="left-icon" style={style}>

        <LeftCircleOutlined  onClick={onClose} style={
            {
                fontSize:24,
                color:'#575757',
                background:"#fff",
                borderRadius:"14px"
            }
        }
         />
        </div>
        <Sidebar />
      </Drawer>
    </Wrapper>
  );
}
const Wrapper = styled.div`

`;
