import axios from "axios";
import store from "../redux/store";

//PROD/DEV/LOCAL SWITCH
// const base_url = "http://localhost:5678"; //process.env.REACT_APP_API_URL;
// const base_url = "https://devapi.restorefastdataendpoint.com/";
const base_url = "https://www.restorefastdataendpoint.com/"; //process.env.REACT_APP_API_URL; Live Address

const axiosInterceptor = axios.create({
  timeout: 45000,
  baseURL: base_url,
  "Content-Type": "application/json;charset=utf-8",
  "Access-Control-Allow-Origin": "*",
});

axiosInterceptor.interceptors.request.use(
  (config) => {
    if (store?.getState()?.signIn?.data?.token) {
      const token = `Bearer ${store?.getState()?.signIn?.data?.token}`;
      config.headers = { Authorization: token };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInterceptor.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error?.response?.data);
  },
);

export default axiosInterceptor;
