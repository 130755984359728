import React from "react";
import { Drawer } from "antd";

const DrawerComponent = ({ open, handleClose, title, children }) => (
  <Drawer
    title={title || "Basic Drawer"}
    placement="right"
    onClose={handleClose}
    open={open}
    // key={placement}
  >
    {children}
  </Drawer>
);

export default DrawerComponent;
