import React, { memo } from "react";
import { Select } from "antd";

const SelectComponent = ({
  defaultValue,
  options,
  handleSelectValue,
  placeholder,
  multiple = false,
}) => {
  return (
    <Select
      size="large"
      defaultValue={defaultValue}
      // style={{
      //   width: 120,
      // }}
      mode={multiple ? "multiple" : ""}
      placeholder={placeholder || "Select"}
      onChange={handleSelectValue}
      options={options}
      allowClear
    />
  );
};
export default memo(SelectComponent);
