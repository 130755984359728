import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// const { Search } = Input;

const SearchField = ({ handleSearch }) => {
  let searchRef;

  const onSearch = (e) => {
    clearTimeout(searchRef);
    searchRef = setTimeout(() => {
      handleSearch(e?.target?.value);
    }, 500);
  };

  return (
    <Input
      placeholder="Search"
      size="large"
      allowClear
      prefix={<SearchOutlined />}
      onChange={onSearch}
    />
    // <Search
    //   placeholder="input search text"
    //   size="large"
    //   allowClear
    //   onSearch={onSearch}
    // />
  );
};
export default SearchField;
