import { createSlice } from "@reduxjs/toolkit";
const initialState={
    data:false
}
const Loadingdata=createSlice({
    name:"Loadingdata",
    initialState,
    reducers:{
        load:(state,action)=>{
            state.data=action.payload
            // Object.assign({}, state, {
            //     data: action.payload
            // })
        }
    }
})
export const {load}=Loadingdata.actions;
export default Loadingdata.reducer;