import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import {
  getcompletedtaskapi,
  getsearchdata,
} from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import SearchField from "../../components/fields/searchField";
import { Button, Image } from "antd";
import ModalComponent from "../../components/modal";
import { useLocation } from "react-router-dom";

export default function Complete() {
  const [openModal, setopenModal] = useState(false);
  const [record, setrecord] = useState();
  const columns = [
    {
      title: "Name",
      dataIndex: "project_name",
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
      key: "project_name",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",

      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",

      key: "end_date",
    },

    {
      title: "Address",
      dataIndex: "addressLocation",
      key: "addressLocation",
      render: (_, record) => (
        <>
          {record?.address},{record?.city},{record?.county}
        </>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <>
          <button
            style={{
              background: "green",
              border: "none",
              color: "white",
              padding: "5px",
            }}
          >
            {record?.status}
          </button>
        </>
      ),
    },
    {
      title: "Project Details",
      dataIndex: "project_name",
      key: "project_name",
      render: (_, dataIndex) => (
        <Userbuttons>
          <Button
            onClick={() => {
              setopenModal(true);
              setrecord(dataIndex);
            }}
          >
            View Details
          </Button>
          <a
            //PROD/DEV/LOCAL SWITCH
            href={`https://us02.procore.com/562949953425469/company/directory/users/${dataIndex.user_id}`} //prod
            // href={`https://sandbox.procore.com/4271567/company/directory/users/${dataIndex.user_id}`} //dev
            target="_blank"
            rel="noreferrer"
          >
            <Button>User Details</Button>
          </a>
        </Userbuttons>
      ),
    },
  ];
  const [loading, setLoading] = useState([]);
  const [data, setdata] = useState([]);
  const [totalCount, settotalcount] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const handleDrawerOpenClose = () => {
    setopenModal((pre) => !pre);
  };
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const handleSearchData = async (filterPath) => {
    setLoading(true);
    const isAccept = 4;
    const res = await getsearchdata(currentPage, limit, searchText, isAccept);
    if (res?.status === 200) {
      settotalcount(res?.data?.TotalCount);
      setdata(res?.data?.finalRequestedData);
    } else errorMessage(res);
    setLoading(false);
  };

  const getdata = async () => {
    setLoading(true);
    let project_id;
    if (params.has("project_id")) {
      project_id = params.get("project_id");
    } else {
      project_id = "All";
    }

    const req = await getcompletedtaskapi(currentPage, limit, project_id);
    if (req?.status === 200) {
      setLoading(false);
      setdata(req?.data?.Requests);
      settotalcount(req?.data?.TotalCount);
    } else {
      errorMessage(req);
      setLoading(false);
    }
  };
  const handleSearch = (searchText) => {
    setCurrentPage(1);
    setSearchText(searchText);
  };
  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page);
  }, []);
  
  useEffect(() => {
    if (searchText) handleSearchData();
    else {
      getdata();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPage]);

  return (
    <CompleteWrapper>
      <div className="search-wrapper">
        <SearchField handleSearch={handleSearch} />
      </div>
      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
      <ModalComponent
        openModal={openModal}
        setOpenModal={handleDrawerOpenClose}
      >
        <Modaldetails>
          <li>
            <spna className="highliter">Emergency Images </spna> :
            {record?.photos.length ? (
              <>
                <br></br>
                {record?.photos.map((e) => (
                  <Image
                    style={{
                      width: "100px",
                      height: "100px",
                      padding: "5px",
                    }}
                    alt=""
                    src={`https://de7nc1t6mbc89.cloudfront.net/${e}`}
                  />
                ))}
              </>
            ) : (
              "N/A"
            )}
          </li>
          <li>
            {" "}
            <span className="highliter">Address : </span>{" "}
            {record?.address ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">Status : </span>
            {record?.status ?? "   N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">Start Date : </span>{" "}
            {record?.start_date ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">Start Time : </span>{" "}
            {record?.start_time ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">End Date : </span>{" "}
            {record?.end_date ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter"> End Time :</span>{" "}
            {record?.end_time ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">City : </span> {record?.city ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter"> comment : </span>{" "}
            {record?.comment.length ? record?.comment : "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">country_code : </span>{" "}
            {record?.country_code ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">county : </span>{" "}
            {record?.county ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">latitude : </span>{" "}
            {record?.latitude ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">longitude : </span>{" "}
            {record?.longitude ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">status : </span>{" "}
            {record?.status ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">state_code : </span>{" "}
            {record?.state_code ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">zip : </span>
            {record?.zip ?? "N/A"}
          </li>
          <li>
            <span className="highliter">Equipment Required : </span>
            {record?.equipment?.map((e) => (
              <>
                {" "}
                {e?.equipment_name}({e?.equipment_size}),{" "}
              </>
            ))}
          </li>
          <li>
            {" "}
            <span className="highliter">labour Required : </span>
            {record?.labor_size ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">labour type : </span>
            {record?.labor_type === 0
              ? "general"
              : record?.labor_type === 1
              ? "skilled"
              : ""}
          </li>
        </Modaldetails>
      </ModalComponent>
    </CompleteWrapper>
  );
}
const CompleteWrapper = styled(OuterContainer)`
  .search-wrapper {
    margin: 20px 0px;
  }
`;
const Modaldetails = styled.div`
  li {
    font-size: 14px;
    padding: 2px;
    .highliter {
      font-weight: bold;
    }
  }
`;
const Userbuttons = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 5px;
`;
